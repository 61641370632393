<!-- =========================================================================================
    File Name: ChartBarChart.vue
    Description: Create bar chart
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card title="Bar Chart" class="mb-base" code-toggler>
    <div class="vx-col w-full sm:w-1/1">
      <p>Sentiment</p>
    </div>
    <div class="mt-5">
      <chartjs-component-bar-chart :height="250" :data="data" :options="options" v-if="show"></chartjs-component-bar-chart>
    </div>
  </vx-card>
</template>

<script>
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";
export default {
  props: {
    posetive: Number,
    negetive: Number,
    neutral: Number
  },
  data() {
    return {
      show: false,
      data: {
        labels: ["Positive", "Negative", "Neutral"],
        datasets: [
          {
            backgroundColor: ["#28C76F", "#EA5455", "#1E1E1E"],
            data: [0, 100]
          }
        ]
      },
      options: {
        legend: { display: false },
        title: {
          display: false,
          text: "Predicted world population (millions) in 2050"
        }
      }
    };
  },
  watch: {
    $route() {
      this.show = false;
      this.data.datasets[0].data = [0, 100];
      setTimeout(() => {
        this.setDAta();
      });
    }
  },
  mounted() {
    this.data.datasets[0].data = [0, 100];
    this.setDAta();
  },
  methods: {
    setDAta() {
      this.data.labels = ["Positive", "Negative", "Neutral"];
      this.data.datasets[0].data.unshift(Math.round(this.neutral));
      this.data.datasets[0].data.unshift(Math.round(this.negetive));
      this.data.datasets[0].data.unshift(Math.round(this.posetive));
      this.show = true;
    }
  },
  components: {
    ChartjsComponentBarChart
  }
};
</script>
<style >
.vx-card .vx-card__header {
  display: none;
}
</style>